import * as React from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { useTrail, animated } from "@react-spring/web";
import useMediaQuery from "@mui/material/useMediaQuery";

type IntroProps = {
  onClick: () => void;
};

export default function Intro(props: IntroProps) {
  const title = "Software Development, One Step at a Time";
  const buttonText = "Our Software Products";
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect small screens
  // create a trail animation for each character in the message
  const trail = useTrail(title.length, {
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { mass: 1, tension: 210, friction: 20 },
  });

  return (
    <Box sx={{p: 5}}>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize={isMobile ? "4rem" : "clamp(6.875rem, 2.3636rem + 2.1818vw, 3rem)"}
      >
        {trail.map((props, index) => (
          <animated.span key={index} style={props}>
            {title[index]}
          </animated.span>
        ))}
      </Typography>

      <Button
        size={isMobile ? "md" : "lg"}
        onClick={props.onClick} endDecorator={<ArrowForward fontSize="large" />} sx={{mt: 2}}>
        {buttonText}
      </Button>
    </Box>
  );
}
