import * as React from 'react';
import Box from '@mui/joy/Box';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import { useTransition, animated, useSpringRef } from '@react-spring/web'
import CssBaseline from '@mui/joy/CssBaseline';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

import framesxTheme from './theme';
import Intro from './pages/Intro';
import OurApps from './pages/OurApps';
import Consulting from './pages/Consulting';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="lg"
      variant="soft"
      color="neutral"
      onClick={() => {
        if (mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
      }}
      sx={{
        position: 'fixed',
        zIndex: 999,
        top: '1rem',
        right: '1rem',
        borderRadius: '50%',
        boxShadow: 'sm',
      }}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function App() {
  const [index, setIndex] = React.useState(0);
  const springRef = useSpringRef();
  const transitions = useTransition(index, {
    ref: springRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%, 0, 0)' },
    config: { tension: 100, friction: 20 } // Adjust for smoothness
  });
  const onClick = () => setIndex((state) => (state + 1) % pages.length);
  const currentYear = new Date().getFullYear(); // Get the current year

  const pages: React.ReactNode[] = [
    <Intro onClick={onClick}/>,
    <OurApps onClick={onClick} />,
    <Consulting />
  ];

  React.useEffect(() => {
    springRef.start();
  }, [index, springRef]);

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <CssBaseline />
      <ColorSchemeToggle />
      <Box sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        {transitions((style, i) => (
          <animated.div style={{...style, position: "absolute", width: "100%"}}>
            {pages[i]}
          </animated.div>
        ))}
      </Box>
    
    {/* Footer */}
    <Box sx={{
      p: "5vh",
      position: "absolute",
      bottom: 0,
      width: "100%",
      textAlign: "center",
    }}>
      <Typography fontWeight="md" fontSize="20px">
        Babystep Technologies, Inc © {currentYear}, all rights reserved
      </Typography>
    </Box>
      
    </CssVarsProvider>
  );
}
