import * as React from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import { useTrail, animated } from "@react-spring/web";
import { useSpring, animated as animatedSpring } from "@react-spring/web";
import Typography from '@mui/joy/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";
import InkBowl from '../assets/companies/inkbowl.png';
import PatientPoint from '../assets/companies/patient-point.png';
import UIowa from '../assets/companies/uiowa.png';
import Reflik from '../assets/companies/reflik.png';
import ShearwaterHealth from '../assets/companies/shearwater-health.png';
import Hilink from '../assets/companies/hilink.png';
import TopTal from '../assets/companies/toptal.png';

const logos = [
  { id: 1, src: "https://www.svgrepo.com/show/303257/paypal-logo.svg", link: "https://paypal.com" },
  { id: 2, src: "https://upload.wikimedia.org/wikipedia/commons/9/98/Groupon_Logo.svg", link: "https://groupon.com" },
  { id: 3, src: InkBowl, link: "https://inkbowl.com" },
  { id: 4, src: PatientPoint, link: "https://patientpoint.com" },
  { id: 5, src: Reflik, link: "https://reflik.com" },
  { id: 6, src: ShearwaterHealth, link: "https://shearwaterhealth.com" },
  { id: 7, src: UIowa, link: "https://uiowa.edu" },
  { id: 8, src: Hilink, link: "https://hilink.co" },
  { id: 9, src: TopTal, link: "https://toptal.com" },
];

export default function Consulting() {
  const title = "We Help Business Build Software";
  const message = "Organizations who trust us";
  const buttonText = "Hire Us";
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect small screens

  // Contact button, decode the email on the client side
  const [email, setEmail] = React.useState("");
  React.useEffect(() => {
    const encodedEmail = ["shen", "marshall", "gmail", "com"];
    setEmail(`${encodedEmail[0]}.${encodedEmail[1]}@${encodedEmail[2]}.${encodedEmail[3]}`);
  }, []);

  const handleContactUs = () => {
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };

  // create a trail animation for each character in the message
  const trail = useTrail(title.length, {
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { mass: 1, tension: 210, friction: 20 },
  });

  // Infinite marquee animation
  const marqueeAnimation = useSpring({
    from: { transform: "translate3d(0%, 0, 0)" },
    to: { transform: "translate3d(-100%, 0, 0)" },
    loop: true,
    config: { duration: 200000 }, // adjust marquee speed
  });

  return (
    <Box sx={{p: 5}}>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize={isMobile ? "4rem" : "clamp(6.875rem, 2.3636rem + 2.1818vw, 3rem)"}
      >
        {trail.map((props, index) => (
          <animated.span key={index} style={props}>
            {title[index]}
          </animated.span>
        ))}
      </Typography>
      <Typography
        level="h4"
        fontWeight="m"
        fontSize={isMobile ? "1.5rem" : "clamp(1.875rem, 2.3636rem + 2.1818vw, 3rem)"}
        mb={isMobile ? 3 : 4}
      >
        {message}
      </Typography>

      {/* Marquee Section */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "150px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          mt: 4,
          borderRadius: "8px",
        }}
      >
        <animatedSpring.div
          style={{
            display: "flex",
            gap: "10rem",
            whiteSpace: "nowrap",
            ...marqueeAnimation,
          }}
        >
          {/* Duplicate logos for seamless marquee */}
          {Array(5).fill(logos).flat().map((logo) => (
            <LogoItem key={logo.id} logo={logo} />
          ))}
        </animatedSpring.div>
      </Box>

      <Button
        size={isMobile ? "md" : "lg"}
        endDecorator={<ArrowForward fontSize="large" />}
        sx={{mt: 2, textTransform: "none"}}
        onClick={handleContactUs}
      >
        {buttonText}
      </Button>
    </Box>
  );
}

// Individual Logo Component
const LogoItem: React.FC<{ logo: { id: number; src: string; link: string } }> = ({
  logo,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100px",
        height: "100px",
        cursor: "pointer",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => window.open(logo.link, "_blank")}
    >
      {/* Logo with hover effect */}
      <animatedSpring.img
        src={logo.src}
        alt="company logo"
        style={{
          width: "100%",
          height: "100px",
          objectFit: "contain",
          filter: hovered ? "none" : "grayscale(100%) brightness(50%)",
          transition: "filter 0.3s ease",
        }}
      />
    </Box>
  );
};
